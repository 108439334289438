import { css } from '@emotion/react';
import React from 'react';
import Layout from '../../components/layout/Layout';
import { GreenBtn } from '../../components/layout/styledComponents';
import Seo from '../../components/seo';
import styled from '@emotion/styled';

const Section = styled.section`
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 16px;
    padding-left: 16px;
`;

export default function Registration() {
    return (
        <Layout>
            <Seo
                title="Patient Portal/Forms - Pikes Peak Oral Surgery & Dental Implant Center"
                description="You may preregister with our office by filling out our secure online patient forms here."
            />
            <Section>
                <h2
                    css={theme => css`
                        margin-bottom: 64px !important;
                        color: ${theme.darkBlue};
                    `}
                >
                    Patient Portal/Forms
                </h2>
                <p>
                    You may preregister with our office by filling out our secure online patient
                    forms. The security and privacy of your personal data is one of our primary
                    concerns and we have taken every precaution to protect it.
                </p>

                <a
                    href="https://patientportal-cs5.carestack.com/?dn=paradigm&ln=7035/#/"
                    target="_blank"
                    style={{ margin: ' 32px 0', display: 'block', maxWidth: 'max-content' }}
                    aria-label="Online Registration Form"
                >
                    <GreenBtn>Online Registration Form</GreenBtn>
                </a>
            </Section>
        </Layout>
    );
}
